/** @define {boolean} */ const SUPPORT_LANG_TW = true

if (SUPPORT_LANG_TW) {
    // noinspection ThisExpressionReferencesGlobalObjectJS
    ;(function(root) {
      FlexSearch.registerLanguage(
        'tw',
        /** @const */ {
          /**
           * http://www.ranks.nl/stopwords
           * @type {Array<string>}
           * @export
           */
  
          filter: [
            '!',
            '"',
            '#',
            '$',
            '%',
            '&',
            '\'',
            '(',
            ')',
            '*',
            '+',
            ',',
            '-',
            '--',
            '.',
            '..',
            '...',
            '......',
            '...................',
            './',
            '.一',
            '.數',
            '.日',
            '/',
            '//',
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            ':',
            '://',
            '::',
            ';',
            '<',
            '=',
            '>',
            '>>',
            '?',
            '@',
            'A',
            'Lex',
            '[',
            '\\',
            ']',
            '^',
            '_',
            '`',
            'exp',
            'sub',
            'sup',
            '|',
            '}',
            '~',
            '~~~~',
            '·',
            '×',
            '×××',
            'Δ',
            'Ψ',
            'γ',
            'μ',
            'φ',
            'φ．',
            'В',
            '—',
            '——',
            '———',
            '‘',
            '’',
            '’‘',
            '“',
            '”',
            '”，',
            '…',
            '……',
            '…………………………………………………③',
            '′∈',
            '′｜',
            '℃',
            'Ⅲ',
            '↑',
            '→',
            '∈［',
            '∪φ∈',
            '≈',
            '①',
            '②',
            '②ｃ',
            '③',
            '③］',
            '④',
            '⑤',
            '⑥',
            '⑦',
            '⑧',
            '⑨',
            '⑩',
            '──',
            '■',
            '▲',
            '　',
            '、',
            '。',
            '〈',
            '〉',
            '《',
            '》',
            '》），',
            '」',
            '『',
            '』',
            '【',
            '】',
            '〔',
            '〕',
            '〕〔',
            '㈧',
            '一',
            '一.',
            '一一',
            '一下',
            '一個',
            '一些',
            '一何',
            '一切',
            '一則',
            '一則通過',
            '一天',
            '一定',
            '一方面',
            '一旦',
            '一時',
            '一來',
            '一樣',
            '一次',
            '一片',
            '一番',
            '一直',
            '一致',
            '一般',
            '一起',
            '一轉眼',
            '一邊',
            '一面',
            '七',
            '萬一',
            '三',
            '三天兩頭',
            '三番兩次',
            '三番五次',
            '上',
            '上下',
            '上升',
            '上去',
            '上來',
            '上述',
            '上面',
            '下',
            '下列',
            '下去',
            '下來',
            '下面',
            '不',
            '不一',
            '不下',
            '不久',
            '不了',
            '不亦樂乎',
            '不僅',
            '不僅...而且',
            '不僅僅',
            '不僅僅是',
            '不會',
            '不但',
            '不但...而且',
            '不光',
            '不免',
            '不再',
            '不力',
            '不單',
            '不變',
            '不只',
            '不可',
            '不可開交',
            '不可抗拒',
            '不同',
            '不外',
            '不外乎',
            '不夠',
            '不大',
            '不如',
            '不妨',
            '不定',
            '不對',
            '不少',
            '不盡',
            '不盡然',
            '不巧',
            '不已',
            '不常',
            '不得',
            '不得不',
            '不得了',
            '不得已',
            '不必',
            '不怎麼',
            '不怕',
            '不惟',
            '不成',
            '不拘',
            '不擇手段',
            '不敢',
            '不料',
            '不斷',
            '不日',
            '不時',
            '不是',
            '不曾',
            '不止',
            '不止一次',
            '不比',
            '不消',
            '不滿',
            '不然',
            '不然的話',
            '不特',
            '不獨',
            '不由得',
            '不知不覺',
            '不管',
            '不管怎樣',
            '不經意',
            '不勝',
            '不能',
            '不能不',
            '不至於',
            '不若',
            '不要',
            '不論',
            '不起',
            '不足',
            '不過',
            '不迭',
            '不問',
            '不限',
            '與',
            '與其',
            '與其說',
            '與否',
            '與此同時',
            '專門',
            '且',
            '且不說',
            '且說',
            '兩者',
            '嚴格',
            '嚴重',
            '個',
            '個人',
            '個別',
            '中小',
            '中間',
            '豐富',
            '序列',
            '臨',
            '臨到',
            '為',
            '為主',
            '為了',
            '為什麼',
            '為什麼',
            '為何',
            '為止',
            '為此',
            '為著',
            '主張',
            '主要',
            '舉凡',
            '舉行',
            '乃',
            '乃至',
            '乃至於',
            '麼',
            '之',
            '之一',
            '之前',
            '之後',
            '之後',
            '之所以',
            '之類',
            '烏乎',
            '乎',
            '乒',
            '乘',
            '乘勢',
            '乘機',
            '乘勝',
            '乘虛',
            '乘隙',
            '九',
            '也',
            '也好',
            '也就是說',
            '也是',
            '也罷',
            '了',
            '瞭解',
            '爭取',
            '二',
            '二來',
            '二話不說',
            '二話沒說',
            '於',
            '於是',
            '於是乎',
            '云云',
            '云爾',
            '互',
            '互相',
            '五',
            '些',
            '交口',
            '亦',
            '產生',
            '親口',
            '親手',
            '親眼',
            '親自',
            '親身',
            '人',
            '人人',
            '人們',
            '人家',
            '人民',
            '什麼',
            '什麼樣',
            '什麼',
            '僅',
            '僅僅',
            '今',
            '今後',
            '今天',
            '今年',
            '今後',
            '介於',
            '仍',
            '仍舊',
            '仍然',
            '從',
            '從不',
            '從嚴',
            '從中',
            '從事',
            '從今以後',
            '從優',
            '從古到今',
            '從古至今',
            '從頭',
            '從寬',
            '從小',
            '從新',
            '從無到有',
            '從早到晚',
            '從未',
            '從來',
            '從此',
            '從此以後',
            '從而',
            '從輕',
            '從速',
            '從重',
            '他',
            '他人',
            '他們',
            '他是',
            '他的',
            '代替',
            '以',
            '以上',
            '以下',
            '以為',
            '以便',
            '以免',
            '以前',
            '以及',
            '以後',
            '以外',
            '以後',
            '以故',
            '以期',
            '以來',
            '以至',
            '以至於',
            '以致',
            '們',
            '任',
            '任何',
            '任憑',
            '任務',
            '企圖',
            '夥同',
            '會',
            '偉大',
            '傳',
            '傳說',
            '傳聞',
            '似乎',
            '似的',
            '但',
            '但凡',
            '但願',
            '但是',
            '何',
            '何樂而不為',
            '何以',
            '何況',
            '何處',
            '何妨',
            '何嘗',
            '何必',
            '何時',
            '何止',
            '何苦',
            '何須',
            '餘外',
            '作為',
            '你',
            '你們',
            '你是',
            '你的',
            '使',
            '使得',
            '使用',
            '例如',
            '依',
            '依據',
            '依照',
            '依靠',
            '便',
            '便於',
            '促進',
            '保持',
            '保管',
            '保險',
            '俺',
            '俺們',
            '倍加',
            '倍感',
            '倒不如',
            '倒不如說',
            '倒是',
            '倘',
            '倘使',
            '倘或',
            '倘然',
            '倘若',
            '借',
            '藉以',
            '藉此',
            '假使',
            '假如',
            '假若',
            '偏偏',
            '做到',
            '偶爾',
            '偶而',
            '儻然',
            '像',
            '兒',
            '允許',
            '元／噸',
            '充其極',
            '充其量',
            '充分',
            '先不先',
            '先後',
            '先後',
            '先生',
            '光',
            '光是',
            '全體',
            '全力',
            '全年',
            '全然',
            '全身心',
            '全部',
            '全都',
            '全面',
            '八',
            '八成',
            '公然',
            '六',
            '兮',
            '共',
            '共同',
            '共總',
            '關於',
            '其',
            '其一',
            '其中',
            '其二',
            '其他',
            '其餘',
            '其後',
            '其它',
            '其實',
            '其次',
            '具體',
            '具體地說',
            '具體來說',
            '具體說來',
            '具有',
            '兼之',
            '內',
            '再',
            '再其次',
            '再則',
            '再有',
            '再次',
            '再者',
            '再者說',
            '再說',
            '冒',
            '衝',
            '決不',
            '決定',
            '決非',
            '況且',
            '準備',
            '湊巧',
            '凝神',
            '幾',
            '幾乎',
            '幾度',
            '幾時',
            '幾番',
            '幾經',
            '凡',
            '凡是',
            '憑',
            '憑藉',
            '出',
            '出於',
            '出去',
            '出來',
            '出現',
            '分別',
            '分頭',
            '分期',
            '分期分批',
            '切',
            '切不可',
            '切切',
            '切勿',
            '切莫',
            '則',
            '則甚',
            '剛',
            '剛好',
            '剛巧',
            '剛才',
            '初',
            '別',
            '別人',
            '別處',
            '別是',
            '別的',
            '別管',
            '別說',
            '到',
            '到了兒',
            '到處',
            '到頭',
            '到頭來',
            '到底',
            '到目前為止',
            '前後',
            '前此',
            '前者',
            '前進',
            '前面',
            '加上',
            '加之',
            '加以',
            '加入',
            '加強',
            '動不動',
            '動輒',
            '勃然',
            '匆匆',
            '十分',
            '千',
            '千萬',
            '千萬千萬',
            '半',
            '單',
            '單單',
            '單純',
            '即',
            '即令',
            '即使',
            '即便',
            '即刻',
            '即如',
            '即將',
            '即或',
            '即是說',
            '即若',
            '卻',
            '卻不',
            '歷',
            '原來',
            '去',
            '又',
            '又及',
            '及',
            '及其',
            '及時',
            '及至',
            '雙方',
            '反之',
            '反之亦然',
            '反之則',
            '反倒',
            '反倒是',
            '反應',
            '反手',
            '反映',
            '反而',
            '反過來',
            '反過來說',
            '取得',
            '取道',
            '受到',
            '變成',
            '古來',
            '另',
            '另一個',
            '另一方面',
            '另外',
            '另悉',
            '另方面',
            '另行',
            '只',
            '只當',
            '只怕',
            '只是',
            '只有',
            '只消',
            '只要',
            '只限',
            '叫',
            '叫做',
            '召開',
            '叮咚',
            '叮噹',
            '可',
            '可以',
            '可好',
            '可是',
            '可能',
            '可見',
            '各',
            '各個',
            '各人',
            '各位',
            '各地',
            '各式',
            '各種',
            '各級',
            '各自',
            '合理',
            '同',
            '同一',
            '同時',
            '同樣',
            '後',
            '後來',
            '後者',
            '後面',
            '向',
            '向使',
            '向著',
            '嚇',
            '嗎',
            '否則',
            '吧',
            '吧噠',
            '吱',
            '呀',
            '呃',
            '呆呆地',
            '吶',
            '嘔',
            '唄',
            '嗚',
            '嗚呼',
            '呢',
            '周圍',
            '呵',
            '呵呵',
            '呸',
            '呼哧',
            '呼啦',
            '咋',
            '和',
            '咚',
            '咦',
            '咧',
            '咱',
            '咱們',
            '咳',
            '哇',
            '哈',
            '哈哈',
            '哉',
            '哎',
            '哎呀',
            '哎喲',
            '譁',
            '嘩啦',
            '喲',
            '哦',
            '哩',
            '哪',
            '哪個',
            '哪些',
            '哪兒',
            '哪天',
            '哪年',
            '哪怕',
            '哪樣',
            '哪邊',
            '哪裡',
            '哼',
            '哼唷',
            '唉',
            '唯有',
            '啊',
            '啊呀',
            '啊哈',
            '啊喲',
            '啐',
            '啥',
            '啦',
            '啪達',
            '啷噹',
            '喀',
            '喂',
            '喏',
            '喔唷',
            '嘍',
            '嗡',
            '嗡嗡',
            '嗬',
            '嗯',
            '噯',
            '嘎',
            '嘎嘎',
            '嘎登',
            '噓',
            '嘛',
            '嘻',
            '嘿',
            '嘿嘿',
            '四',
            '因',
            '因為',
            '因了',
            '因此',
            '因著',
            '因而',
            '固',
            '固然',
            '在',
            '在下',
            '在於',
            '地',
            '均',
            '堅決',
            '堅持',
            '基於',
            '基本',
            '基本上',
            '處在',
            '處處',
            '處理',
            '複雜',
            '多',
            '多麼',
            '多虧',
            '多多',
            '多多少少',
            '多多益善',
            '多少',
            '多年前',
            '多年來',
            '多數',
            '多次',
            '夠瞧的',
            '大',
            '大不了',
            '大舉',
            '大事',
            '大體',
            '大體上',
            '大凡',
            '大力',
            '大多',
            '大多數',
            '大大',
            '大家',
            '大張旗鼓',
            '大批',
            '大抵',
            '大概',
            '大略',
            '大約',
            '大致',
            '大都',
            '大量',
            '大面兒上',
            '失去',
            '奇',
            '奈',
            '奮勇',
            '她',
            '她們',
            '她是',
            '她的',
            '好',
            '好在',
            '好的',
            '好象',
            '如',
            '如上',
            '如上所述',
            '如下',
            '如今',
            '如何',
            '如其',
            '如前所述',
            '如同',
            '如常',
            '如是',
            '如期',
            '如果',
            '如次',
            '如此',
            '如此等等',
            '如若',
            '始而',
            '姑且',
            '存在',
            '存心',
            '孰料',
            '孰知',
            '寧',
            '寧可',
            '寧願',
            '寧肯',
            '它',
            '它們',
            '它們的',
            '它是',
            '它的',
            '安全',
            '完全',
            '完成',
            '定',
            '實現',
            '實際',
            '宣佈',
            '容易',
            '密切',
            '對',
            '對於',
            '對應',
            '對待',
            '對方',
            '對比',
            '將',
            '將才',
            '將要',
            '將近',
            '小',
            '少數',
            '爾',
            '爾後',
            '爾爾',
            '爾等',
            '尚且',
            '尤其',
            '就',
            '就地',
            '就是',
            '就是了',
            '就是說',
            '就此',
            '就算',
            '就要',
            '盡',
            '儘可能',
            '盡如人意',
            '盡心盡力',
            '盡心竭力',
            '儘快',
            '儘早',
            '盡然',
            '儘管',
            '儘管如此',
            '儘量',
            '局外',
            '居然',
            '屆時',
            '屬於',
            '屢',
            '屢屢',
            '屢次',
            '屢次三番',
            '豈',
            '豈但',
            '豈止',
            '豈非',
            '川流不息',
            '左右',
            '巨大',
            '鞏固',
            '差一點',
            '差不多',
            '己',
            '已',
            '已矣',
            '已經',
            '巴',
            '巴巴',
            '帶',
            '幫助',
            '常',
            '常常',
            '常言說',
            '常言說得好',
            '常言道',
            '平素',
            '年復一年',
            '並',
            '並不',
            '並不是',
            '並且',
            '並排',
            '並無',
            '並沒',
            '並沒有',
            '並肩',
            '並非',
            '廣大',
            '廣泛',
            '應當',
            '應用',
            '應該',
            '庶乎',
            '庶幾',
            '開外',
            '開始',
            '開展',
            '引起',
            '弗',
            '彈指之間',
            '強烈',
            '強調',
            '歸',
            '歸根到底',
            '歸根結底',
            '歸齊',
            '當',
            '當下',
            '當中',
            '當兒',
            '當前',
            '當即',
            '當口兒',
            '當地',
            '當場',
            '當頭',
            '當庭',
            '當時',
            '當然',
            '當真',
            '當著',
            '形成',
            '徹夜',
            '徹底',
            '彼',
            '彼時',
            '彼此',
            '往',
            '往往',
            '待',
            '待到',
            '很',
            '很多',
            '很少',
            '後來',
            '後面',
            '得',
            '得了',
            '得出',
            '得到',
            '得天獨厚',
            '得起',
            '心裡',
            '必',
            '必定',
            '必將',
            '必然',
            '必要',
            '必須',
            '快',
            '快要',
            '忽地',
            '忽然',
            '怎',
            '怎麼',
            '怎麼辦',
            '怎麼樣',
            '怎奈',
            '怎樣',
            '怎麼',
            '怕',
            '急匆匆',
            '怪',
            '怪不得',
            '總之',
            '總是',
            '總的來看',
            '總的來說',
            '總的說來',
            '總結',
            '總而言之',
            '恍然',
            '恐怕',
            '恰似',
            '恰好',
            '恰如',
            '恰巧',
            '恰恰',
            '恰恰相反',
            '恰逢',
            '您',
            '您們',
            '您是',
            '惟其',
            '慣常',
            '意思',
            '憤然',
            '願意',
            '慢說',
            '成為',
            '成年',
            '成年累月',
            '成心',
            '我',
            '我們',
            '我是',
            '我的',
            '或',
            '或則',
            '或多或少',
            '或是',
            '或曰',
            '或者',
            '或許',
            '戰鬥',
            '截然',
            '截至',
            '所',
            '所以',
            '所在',
            '所幸',
            '所有',
            '所謂',
            '才',
            '才能',
            '撲通',
            '打',
            '打從',
            '開啟天窗說亮話',
            '擴大',
            '把',
            '抑或',
            '抽冷子',
            '攔腰',
            '拿',
            '按',
            '按時',
            '按期',
            '按照',
            '按理',
            '按說',
            '挨個',
            '挨家挨戶',
            '挨次',
            '挨著',
            '挨門挨戶',
            '挨門逐戶',
            '換句話說',
            '換言之',
            '據',
            '據實',
            '據悉',
            '據我所知',
            '據此',
            '據稱',
            '據說',
            '掌握',
            '接下來',
            '接著',
            '接著',
            '接連不斷',
            '放量',
            '故',
            '故意',
            '故此',
            '故而',
            '敞開兒',
            '敢',
            '敢於',
            '敢情',
            '數/',
            '整個',
            '斷然',
            '方',
            '方便',
            '方才',
            '方能',
            '方面',
            '旁人',
            '無',
            '無寧',
            '無法',
            '無論',
            '既',
            '既...又',
            '既往',
            '既是',
            '既然',
            '日復一日',
            '日漸',
            '日益',
            '日臻',
            '日見',
            '時候',
            '昂然',
            '明顯',
            '明確',
            '是',
            '是不是',
            '是以',
            '是否',
            '是的',
            '顯然',
            '顯著',
            '普通',
            '普遍',
            '暗中',
            '暗地裡',
            '暗自',
            '更',
            '更為',
            '更加',
            '更進一步',
            '曾',
            '曾經',
            '替',
            '替代',
            '最',
            '最後',
            '最大',
            '最好',
            '最後',
            '最近',
            '最高',
            '有',
            '有些',
            '有關',
            '有利',
            '有力',
            '有及',
            '有所',
            '有效',
            '有時',
            '有點',
            '有的',
            '有的是',
            '有著',
            '有著',
            '望',
            '朝',
            '朝著',
            '末##末',
            '本',
            '本人',
            '本地',
            '本著',
            '本身',
            '權時',
            '來',
            '來不及',
            '來得及',
            '來看',
            '來著',
            '來自',
            '來講',
            '來說',
            '極',
            '極為',
            '極了',
            '極其',
            '極力',
            '極大',
            '極度',
            '極端',
            '構成',
            '果然',
            '果真',
            '某',
            '某個',
            '某些',
            '某某',
            '根據',
            '根本',
            '格外',
            '梆',
            '概',
            '次第',
            '歡迎',
            '歟',
            '正值',
            '正在',
            '正如',
            '正巧',
            '正常',
            '正是',
            '此',
            '此中',
            '此後',
            '此地',
            '此處',
            '此外',
            '此時',
            '此次',
            '此間',
            '殆',
            '毋寧',
            '每',
            '每個',
            '每天',
            '每年',
            '每當',
            '每時每刻',
            '每每',
            '每逢',
            '比',
            '比及',
            '比如',
            '比如說',
            '比方',
            '比照',
            '比起',
            '比較',
            '畢竟',
            '毫不',
            '毫無',
            '毫無例外',
            '毫無保留地',
            '汝',
            '沙沙',
            '沒',
            '沒奈何',
            '沒有',
            '沿',
            '沿著',
            '注意',
            '活',
            '深入',
            '清楚',
            '滿',
            '滿足',
            '漫說',
            '焉',
            '然',
            '然則',
            '然後',
            '然後',
            '然而',
            '照',
            '照著',
            '牢牢',
            '特別是',
            '特殊',
            '特點',
            '猶且',
            '猶自',
            '獨',
            '獨自',
            '猛然',
            '猛然間',
            '率爾',
            '率然',
            '現代',
            '現在',
            '理應',
            '理當',
            '理該',
            '瑟瑟',
            '甚且',
            '甚麼',
            '甚或',
            '甚而',
            '甚至',
            '甚至於',
            '用',
            '用來',
            '甫',
            '甭',
            '由',
            '由於',
            '由是',
            '由此',
            '由此可見',
            '略',
            '略為',
            '略加',
            '略微',
            '白',
            '白白',
            '的',
            '的確',
            '的話',
            '皆可',
            '目前',
            '直到',
            '直接',
            '相似',
            '相信',
            '相反',
            '相同',
            '相對',
            '相對而言',
            '相應',
            '相當',
            '相等',
            '省得',
            '看',
            '看上去',
            '看出',
            '看到',
            '看來',
            '看樣子',
            '看看',
            '看見',
            '看起來',
            '真是',
            '真正',
            '眨眼',
            '著',
            '著呢',
            '矣',
            '矣乎',
            '矣哉',
            '知道',
            '砰',
            '確定',
            '碰巧',
            '社會主義',
            '離',
            '種',
            '積極',
            '移動',
            '究竟',
            '窮年累月',
            '突出',
            '突然',
            '竊',
            '立',
            '立刻',
            '立即',
            '立地',
            '立時',
            '立馬',
            '竟',
            '竟然',
            '竟而',
            '第',
            '第二',
            '等',
            '等到',
            '等等',
            '策略地',
            '簡直',
            '簡而言之',
            '簡言之',
            '管',
            '類如',
            '粗',
            '精光',
            '緊接著',
            '累年',
            '累次',
            '純',
            '純粹',
            '縱',
            '縱令',
            '縱使',
            '縱然',
            '練習',
            '組成',
            '經',
            '經常',
            '經過',
            '結合',
            '結果',
            '給',
            '絕',
            '絕不',
            '絕對',
            '絕非',
            '絕頂',
            '繼之',
            '繼後',
            '繼續',
            '繼而',
            '維持',
            '綜上所述',
            '縷縷',
            '罷了',
            '老',
            '老大',
            '老是',
            '老老實實',
            '考慮',
            '者',
            '而',
            '而且',
            '而況',
            '而又',
            '而後',
            '而外',
            '而已',
            '而是',
            '而言',
            '而論',
            '聯絡',
            '聯袂',
            '背地裡',
            '背靠背',
            '能',
            '能否',
            '能夠',
            '騰',
            '自',
            '自個兒',
            '自從',
            '自各兒',
            '自後',
            '自家',
            '自己',
            '自打',
            '自身',
            '臭',
            '至',
            '至於',
            '至今',
            '至若',
            '致',
            '般的',
            '良好',
            '若',
            '若夫',
            '若是',
            '若果',
            '若非',
            '範圍',
            '莫',
            '莫不',
            '莫不然',
            '莫如',
            '莫若',
            '莫非',
            '獲得',
            '藉以',
            '雖',
            '雖則',
            '雖然',
            '雖說',
            '蠻',
            '行為',
            '行動',
            '表明',
            '表示',
            '被',
            '要',
            '要不',
            '要不是',
            '要不然',
            '要麼',
            '要是',
            '要求',
            '見',
            '規定',
            '覺得',
            '譬喻',
            '譬如',
            '認為',
            '認真',
            '認識',
            '讓',
            '許多',
            '論',
            '論說',
            '設使',
            '設或',
            '設若',
            '誠如',
            '誠然',
            '話說',
            '該',
            '該當',
            '說明',
            '說來',
            '說說',
            '請勿',
            '諸',
            '諸位',
            '諸如',
            '誰',
            '誰人',
            '誰料',
            '誰知',
            '謹',
            '豁然',
            '賊死',
            '賴以',
            '趕',
            '趕快',
            '趕早不趕晚',
            '起',
            '起先',
            '起初',
            '起頭',
            '起來',
            '起見',
            '起首',
            '趁',
            '趁便',
            '趁勢',
            '趁早',
            '趁機',
            '趁熱',
            '趁著',
            '越是',
            '距',
            '跟',
            '路經',
            '轉動',
            '轉變',
            '轉貼',
            '轟然',
            '較',
            '較為',
            '較之',
            '較比',
            '邊',
            '達到',
            '達旦',
            '迄',
            '迅速',
            '過',
            '過於',
            '過去',
            '過來',
            '運用',
            '近',
            '近幾年來',
            '近年來',
            '近來',
            '還',
            '還是',
            '還有',
            '還要',
            '這',
            '這一來',
            '這個',
            '這麼',
            '這麼些',
            '這麼樣',
            '這麼點兒',
            '這些',
            '這會兒',
            '這兒',
            '這就是說',
            '這時',
            '這樣',
            '這次',
            '這點',
            '這種',
            '這般',
            '這邊',
            '這裡',
            '這麼',
            '進入',
            '進去',
            '進來',
            '進步',
            '進而',
            '進行',
            '連',
            '連同',
            '連聲',
            '連日',
            '連日來',
            '連袂',
            '連連',
            '遲早',
            '迫於',
            '適應',
            '適當',
            '適用',
            '逐步',
            '逐漸',
            '通常',
            '通過',
            '造成',
            '逢',
            '遇到',
            '遭到',
            '遵循',
            '遵照',
            '避免',
            '那',
            '那個',
            '那麼',
            '那麼些',
            '那麼樣',
            '那些',
            '那會兒',
            '那兒',
            '那時',
            '那末',
            '那樣',
            '那般',
            '那邊',
            '那裡',
            '那麼',
            '部分',
            '都',
            '鄙人',
            '採取',
            '裡面',
            '重大',
            '重新',
            '重要',
            '鑑於',
            '針對',
            '長期以來',
            '長此下去',
            '長線',
            '長話短說',
            '問題',
            '間或',
            '防止',
            '阿',
            '附近',
            '陳年',
            '限制',
            '陡然',
            '除',
            '除了',
            '除卻',
            '除去',
            '除外',
            '除開',
            '除此',
            '除此之外',
            '除此以外',
            '除此而外',
            '除非',
            '隨',
            '隨後',
            '隨時',
            '隨著',
            '隨著',
            '隔夜',
            '隔日',
            '難得',
            '難怪',
            '難說',
            '難道',
            '難道說',
            '集中',
            '零',
            '需要',
            '非但',
            '非常',
            '非徒',
            '非得',
            '非特',
            '非獨',
            '靠',
            '頂多',
            '頃',
            '頃刻',
            '頃刻之間',
            '頃刻間',
            '順',
            '順著',
            '頓時',
            '頗',
            '風雨無阻',
            '飽',
            '首先',
            '馬上',
            '高低',
            '高興',
            '默然',
            '默默地',
            '齊',
            '︿',
            '！',
            '＃',
            '＄',
            '％',
            '＆',
            '＇',
            '（',
            '）',
            '）÷（１－',
            '）、',
            '＊',
            '＋',
            '＋ξ',
            '＋＋',
            '，',
            '，也',
            '－',
            '－β',
            '－－',
            '－［＊］－',
            '．',
            '／',
            '０',
            '０：２',
            '１',
            '１．',
            '１２％',
            '２',
            '２．３％',
            '３',
            '４',
            '５',
            '５：０',
            '６',
            '７',
            '８',
            '９',
            '：',
            '；',
            '＜',
            '＜±',
            '＜Δ',
            '＜λ',
            '＜φ',
            '＜＜',
            '＝',
            '＝″',
            '＝☆',
            '＝（',
            '＝－',
            '＝［',
            '＝｛',
            '＞',
            '＞λ',
            '？',
            '＠',
            'Ａ',
            'ＬＩ',
            'Ｒ．Ｌ．',
            'ＺＸＦＩＴＬ',
            '［',
            '［①①］',
            '［①②］',
            '［①③］',
            '［①④］',
            '［①⑤］',
            '［①⑥］',
            '［①⑦］',
            '［①⑧］',
            '［①⑨］',
            '［①Ａ］',
            '［①Ｂ］',
            '［①Ｃ］',
            '［①Ｄ］',
            '［①Ｅ］',
            '［①］',
            '［①ａ］',
            '［①ｃ］',
            '［①ｄ］',
            '［①ｅ］',
            '［①ｆ］',
            '［①ｇ］',
            '［①ｈ］',
            '［①ｉ］',
            '［①ｏ］',
            '［②',
            '［②①］',
            '［②②］',
            '［②③］',
            '［②④',
            '［②⑤］',
            '［②⑥］',
            '［②⑦］',
            '［②⑧］',
            '［②⑩］',
            '［②Ｂ］',
            '［②Ｇ］',
            '［②］',
            '［②ａ］',
            '［②ｂ］',
            '［②ｃ］',
            '［②ｄ］',
            '［②ｅ］',
            '［②ｆ］',
            '［②ｇ］',
            '［②ｈ］',
            '［②ｉ］',
            '［②ｊ］',
            '［③①］',
            '［③⑩］',
            '［③Ｆ］',
            '［③］',
            '［③ａ］',
            '［③ｂ］',
            '［③ｃ］',
            '［③ｄ］',
            '［③ｅ］',
            '［③ｇ］',
            '［③ｈ］',
            '［④］',
            '［④ａ］',
            '［④ｂ］',
            '［④ｃ］',
            '［④ｄ］',
            '［④ｅ］',
            '［⑤］',
            '［⑤］］',
            '［⑤ａ］',
            '［⑤ｂ］',
            '［⑤ｄ］',
            '［⑤ｅ］',
            '［⑤ｆ］',
            '［⑥］',
            '［⑦］',
            '［⑧］',
            '［⑨］',
            '［⑩］',
            '［＊］',
            '［－',
            '［］',
            '］',
            '］∧′＝［',
            '］［',
            '＿',
            'ａ］',
            'ｂ］',
            'ｃ］',
            'ｅ］',
            'ｆ］',
            'ｎｇ昉',
            '｛',
            '｛－',
            '｜',
            '｝',
            '｝＞',
            '～',
            '～±',
            '～＋',
            '￥',
          ],
  
          /**
           * @type {Object<string, string>}
           * @export
           */
  
          stemmer: {
              s: '',
          },
        }
      )
    })(this)
  }
  