import React, { Component } from 'react'
import { Link } from 'gatsby'


// var nodejieba = require("nodejieba");
// nodejieba.load({dict: '../../../dictTW.txt'});
// var FlexSearch = require("flexsearch")

// Search component
class Search extends Component {
  state = {
    query: '',
    results: [],
  }

  render() {
    const ResultList = () => {
      if (this.state.results.length > 0) {
        return this.state.results.map((page, i) => {
          console.log(page.url);
            return  (
          <div className="item-search" key={i}>
            <Link to={'blog/'+page.url} className="link">
              <h4>{page.title}</h4>
            </Link>
          </div>
        )})
      } else if (this.state.query.length > 2) {
        return 'No results for ' + this.state.query
      } else if (
        this.state.results.length === 0 &&
        this.state.query.length > 0
      ) {
        return 'Please insert at least 3 characters'
      } else {
        return ''
      }
    }

    return (
      <div className={this.props.classNames}>
        <input
          className="search__input"
          type="text"
          onChange={this.search}
          placeholder={'Search'}
        />
        <div className="search__list">
          <ResultList />
        </div>
      </div>
    )
  }

  getSearchResults(query) {
    var index = window.__FLEXSEARCH__.tw.index
    var store = window.__FLEXSEARCH__.tw.store
    if (!query || !index) {
      return []
    } else {
      var results = []
    //   var index = FlexSearch.create({
    //     encode: false,
    //     tokenize: function(str){
    //       return nodejieba.cut(str);
    //     }
    //   });
        
    //     // index.add(1,"一个单词");
    //   var result = index.search(query);
    //   console.log(result);
      console.log(query)
      Object.keys(index).forEach(idx => {
        console.log(index[idx].values)
        results.push(...index[idx].values.search(query))
      })

      results = Array.from(new Set(results))

      var nodes = store
        .filter(node => (results.includes(node.id) ? node : null))
        .map(node => node.node)

      return nodes
    }
  }

  search = event => {
    const query = event.target.value
    if (this.state.query.length > 0) {
      const results = this.getSearchResults(query)
      this.setState({ results: results, query: query })
    } else {
      this.setState({ results: [], query: query })
    }
  }
}

export default Search